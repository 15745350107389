import React, { useState } from "react";
// import { useTranslation } from 'react-i18next';
import "../../static/css/productPage/cartButtons.css";
import { Link } from "react-router-dom";
import SendProductLink from "./SendProductLink";
// import optimizedConvertAndRound from '../../helper/OptimizedConvertAndRoundPrice';

// Because of the language code in the URL, we need to map the language code to the language code used in the URL 
const LangMapper = {
  "en" : "eng",
  "de" : "ge",
  "fr" : "fr",
  "es" : "sp",
  "pt" : "po",
  "it" : "it",
}

const CartButtons = ({
  downloadProduct,
  cartItem,
  type,
  inApp,
  cartTitle,
  cartDescription,
}) => {
  // const { t } = useTranslation();
  const [showSendProductLink, setShowSendProductLink] = useState(false);
  const localStorageValues = JSON.parse(localStorage.getItem("currencyData"));
  const { currency_code, currency_symbol, exchange_rate } =
    localStorageValues || {};
  const lang = localStorage.getItem("lang") || "en";

  function getDeviceType(userAgent) {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|Touch/i.test(
      userAgent
    );
  }

  const submitEmail = () => {
    setShowSendProductLink(true);
  };

  return (
    <>
      {type === "free" ? (
        inApp ? (
          <div className="in-app-free-product">
            <div className="in-app-info">
              <span>{cartTitle}</span>
              {cartDescription
                ?.split(/(?<![0-9])\.(?![0-9])/)
                .slice(0, 2)
                .map((line) => {
                  return <p key={line.id}>{line.trim()}.</p>;
                })}
            </div>
            {!getDeviceType(navigator.userAgent) ? (
              <Link to={downloadProduct}>Download Now</Link>
            ) : (
              <button onClick={() => submitEmail()}>Download Now</button>
            )}
          </div>
        ) : (
          <div className="free-product">
            <p>FREE for Windows</p>
            {!getDeviceType(navigator.userAgent) ? (
              <Link to={downloadProduct}>Download Now</Link>
            ) : (
              <button onClick={() => submitEmail()}>Download Now</button>
            )}
          </div>
        )
      ) : (
        <section className="cart-section">
          <div className="free-trial">
            <p>FREE Trial</p>
            {!getDeviceType(navigator.userAgent) ? (
              <Link to={downloadProduct}>Download Now</Link>
            ) : (
              <button onClick={() => submitEmail()}>Download Now</button>
            )}
          </div>
          <div className="paid-block">
            <div className="paid-link">
              <p>
                Price: {currency_symbol}
                {parseFloat(
                  (cartItem?.[0].catagory_price * exchange_rate).toFixed(2)
                )}{" "}
                {currency_code}
              </p>
              {/* TODO: remove this in next version */}
              <Link
                to={
                  cartTitle === "My Screen Recorder Pro"
                    ? `https://store.deskshare.com/AddProduct.aspx?ProductId1=192&Lang=${LangMapper[lang]}`
                    : "purchase"
                }
                target={
                  cartTitle === "My Screen Recorder Pro" ? "_blank" : "_self"
                }
              >
                <svg
                  fill="#fff"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  viewBox="0 0 902.86 902.86"
                  xmlSpace="preserve"
                  stroke="#fff"
                  strokeWidth="0.009028600000000001"
                  transform="rotate(0)"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    stroke="#fff"
                    strokeWidth="30.697240000000004"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <g>
                      <g>
                        <path d="M671.504,577.829l110.485-432.609H902.86v-68H729.174L703.128,179.2L0,178.697l74.753,399.129h596.751V577.829z M685.766,247.188l-67.077,262.64H131.199L81.928,246.756L685.766,247.188z"></path>{" "}
                        <path d="M578.418,825.641c59.961,0,108.743-48.783,108.743-108.744s-48.782-108.742-108.743-108.742H168.717 c-59.961,0-108.744,48.781-108.744,108.742s48.782,108.744,108.744,108.744c59.962,0,108.743-48.783,108.743-108.744 c0-14.4-2.821-28.152-7.927-40.742h208.069c-5.107,12.59-7.928,26.342-7.928,40.742 C469.675,776.858,518.457,825.641,578.418,825.641z M209.46,716.897c0,22.467-18.277,40.744-40.743,40.744 c-22.466,0-40.744-18.277-40.744-40.744c0-22.465,18.277-40.742,40.744-40.742C191.183,676.155,209.46,694.432,209.46,716.897z M619.162,716.897c0,22.467-18.277,40.744-40.743,40.744s-40.743-18.277-40.743-40.744c0-22.465,18.277-40.742,40.743-40.742 S619.162,694.432,619.162,716.897z"></path>
                      </g>
                    </g>
                  </g>
                </svg>
                <span>Buy Now</span>
              </Link>
            </div>
            <p>100% Commitment to Quality Immediate delivery</p>
          </div>
        </section>
      )}
      {showSendProductLink && <SendProductLink productLink={downloadProduct} />}
    </>
  );
};

export default CartButtons;
