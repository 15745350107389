import React, { Suspense, useEffect } from "react";
import * as Sentry from "@sentry/react";
import "./i18n";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ProductPage from "./pages/ProductPage";
import { HomePage } from "./pages/HomePage";
import DownloadPage from "./pages/DownloadPage";
import SupportForums from './pages/support/SupportForums';
import TechnicalSupport from "./pages/support/TechnicalSupport";
import LostLicenseKey from "./pages/support/LostLicenseKey";
import PurchasePrioritySupport from "./pages/support/PurchasePrioritySupport";
import ContactUs from "./pages/company/ContactUs";
import AboutDeskshare from "./pages/company/AboutDeskshare";
import Articles from "./pages/resources/Articles";
import VideoTutorialsAndManuals from "./pages/resources/VideoTutorialsAndManuals";
import MediaCoverage from "./pages/MediaCoverage";
import WhatsNew from "./pages/WhatsNew";
import NotFound from "./pages/NotFound";
import Reviews from "./pages/Reviews";
import DetailedArticle from "./pages/support/DetailedArticle";
import MyPCInfo from "./pages/MyPCInfo";
import UserManual from "./pages/support/UserManual";
import CameraRequest from "./pages/CameraRequest";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PrivacyPolicyApp from "./pages/PrivacyPolicyApp";
import Faq from "./pages/support/Faq";
import { AddLangToPath } from "./helper/AddLanguagePath";
import PurchasePage from "./pages/PurchasePage";
import SecurityMonitorProPurchasePage from "./pages/SecurityMonitorProPurchasePage";
const router = createBrowserRouter([
  {
    element: <App />,
    // loader: appdata,
    children: [
      {
        path: AddLangToPath("/"),
        element: <HomePage />,
        // loader:homedata
      },
      {
        path: AddLangToPath("/:productSlug"),
        element: <ProductPage />,
        // loader: productdata,
      },
      {
        path: AddLangToPath("/video-surveillance-software/purchase"),
        element: <SecurityMonitorProPurchasePage />,
      },
      {
        path: AddLangToPath("/:productSlug/purchase"),
        element: <PurchasePage />,
        // loader: purchasedata,
      },
      {
        path: AddLangToPath("/:productSlug/whatsnew/:whatsNewSlug"),
        element: <WhatsNew />,
        // loader: productdata,
      },
      {
        path: AddLangToPath("/:productSlug/reviews"),
        element: <Reviews />,
        // loader: productdata,
      },
      {
        path: AddLangToPath("/download"),
        element: <DownloadPage />,
        // loader:homedata
      },
      // {
      //   path: "/forum/",
      //   element: <SupportForums />,
      // },
      {
        path: AddLangToPath("/product-support"),
        element: <TechnicalSupport />,
      },
      {
        path: AddLangToPath("/lost-license-key"),
        element: <LostLicenseKey />,
      },
      {
        path: AddLangToPath("/priority-support"),
        element: <PurchasePrioritySupport />,
      },
      {
        path: AddLangToPath("/articles"),
        element: <Articles />,
        element: <Articles />,
      },
      {
        path: AddLangToPath("/:articleSlug/:detailedArticleSlug"),
        element: <DetailedArticle />,
      },
      {
        path: AddLangToPath("/tutorials-manuals"),
        element: <VideoTutorialsAndManuals />,
      },
      {
        path: AddLangToPath("/help/:product_name_slug/:guideSlug"),
        element: <UserManual />,
      },
      {
        path: AddLangToPath("/faq/:product_name_slug/:guideSlug"),
        element: <Faq />,
      },
      {
        path: AddLangToPath("/about-deskshare"),
        element: <AboutDeskshare />,
      },
      {
        path: AddLangToPath("/contact-us"),
        element: <ContactUs />,
      },
      {
        path: AddLangToPath("/media-coverage"),
        element: <MediaCoverage />,
      },
      {
        path: AddLangToPath("/mypcinfo"),
        element: <MyPCInfo />,
      },
      {
        path: AddLangToPath("/camera-request"),
        element: <CameraRequest />,
      },
      {
        path: AddLangToPath("/privacy-policy"),
        element: <PrivacyPolicy />,
      },
      {
        path: AddLangToPath("/app-privacy-policy"),
        element: <PrivacyPolicyApp />,
      },
      {
        path: AddLangToPath("*"),
        element: <NotFound />,
      },
    ],
  },
]);

// https://4b64cd654b80de33e995279aaaea3087@o4506269455286272.ingest.us.sentry.io/4507208825307136

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://2b2d3e33cbb0e6d7c2c878b446a8645c@o4506269455286272.ingest.us.sentry.io/4506522376863744",
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    tracesSampleRate: 1.0,
    // tracePropagationTargets: ["localhost", "192.168.1.170"],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense fallback="Loading...">
      <RouterProvider router={router} />
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
