import Cookies from "js-cookie";
import axios from "axios";

// export const getShoppingCartLoginStatus = async () => {
//     axios.get("https://shop2.deskshare.com/wp-json/deskshare/v1/is_user_logged_in", {
//         // headers: {
//         //     'X-Client-Request': 'true',
//         //     "Authorization": `Basic ${process.env.REACT_APP_SHOPPING_CART_AUTH_TOKEN}`
//         // }
//         headers: {
//             'X-Client-Request': 'true'
//         },
//         withCredentials: true
//     })
//         .then(response => {
//             console.log(response.data);
//         })
//         .catch(error => {
//             console.error(error.response || error);
//         });
// };

// export const getShoppingCartLoginStatus = async () => {
//     try {
//         const response = await axios.get("https://shop2.deskshare.com/wp-json/deskshare/v1/is_user_logged_in", {
//             headers: {
//                 'X-Client-Request': 'true'
//             },
//             withCredentials: true
//         });
//         console.log(response.data);
//     } catch (error) {
//         console.error(error.response || error);
//     }
// };

// export const getShoppingCartLoginStatus = async () => {
//   if (localStorage.getItem("is_user") === "true") {
//     localStorage.setItem("isuser_loggedin", "true");
//     return { isuser_loggedin: true, is_user: true };
//   }

//   return { isuser_loggedin: false, is_user: false };
// };

export const getShoppingCartLoginStatus = () => {
  const isuser_loggedin = [
    ...Object.keys(Cookies.get()).filter(
      (key) =>
        key.startsWith("wordpress") ||
        (key.startsWith("woocommerce") && key !== "woocommerce_test_custom")
    ),
  ].some((item) => item);

  let is_user = [Cookies.get("woocommerce_test_custom")].some((item) => item);

  if (isuser_loggedin) {
    is_user = true;
  }
  
  return { isuser_loggedin, is_user };
};

// export const getShoppingCartLoginStatus = async () => {
//   const url =
//     "https://test.shop.deskshare.com/wp-json/v1/is_user_logged_in?username=alice.kevin";

// const username = process.env.WORDPRESS_USERNAME;
// const password = process.env.WORDPRESS_PASSWORD;

//   const headers = {
//     "Authorization":
//       "Basic " + btoa(username + ":" + password),
//   };

//   axios
//     .get(url, { headers: headers })
//     .then((response) => {
//       console.log(response.data);
//     })
//     .catch((error) => {
//       console.error("There was a problem with the axios operation:", error);
//     });
// };
