import React, { useState } from "react";
import "../../static/css/homePage/displayProducts.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import uuid from "react-uuid";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DisplayProducts = ({ product }) => {
  const [playingVideo, setPlayingVideo] = useState(null);
  const [modal, setModal] = useState(false);

  const openVideo = (videoLink) => {
    if (videoLink) {
      setPlayingVideo(videoLink);
      setModal(true);
    }
  };

  let prevLength = 0;

  return Object.keys(product).map((type) => {
    let currentLength = product[type].length;
    let className = "";

    if ((currentLength === 1 || currentLength === 2) && prevLength === 0) {
      className = "side-by-side";
    } else if (currentLength === 1 && prevLength === 1) {
      className = "side-by-side";
    } else if (currentLength === 1 && prevLength === 2) {
      className = "side-by-side";
    } else if (
      (currentLength === 3 && prevLength === 3) ||
      (currentLength === 2 && prevLength === 3) ||
      (currentLength === 3 && prevLength === 2)
    ) {
      className = "one-below-one";
    }

    prevLength = currentLength;
    return (
    //   <div className={className === "side-by-side" ? className : "one-by-one"}>
      <div className="one-by-one">
        <div
          className={`product-cart-container ${
            className === "one-below-one" ? className : ""
          }`}
          key={uuid()}
        >
          <h3 id={type}>{type}</h3>
          <div className="products">
            {product[type].map((card) => {
              if (card.product_title) {
                return (
                  <>
                    <Link
                      to={card.product_slug}
                      className="product-card"
                      key={uuid()}
                    >
                      <Link
                        to={card.product_slug}
                        className="product-name-icon"
                      >
                        <div className="card-title">
                          <LazyLoadImage
                            className="card-icon"
                            src={card.product_icon}
                            alt={card.alt_text}
                          />
                          <h4>{card.product_name}</h4>
                        </div>
                      </Link>
                      <Link to={card.product_slug} className="product-info">
                        <p>{card.product_title}</p>
                      </Link>
                      {/* {!card.video ? (
                        <Link to={card.product_slug}>
                          <div>
                            <LazyLoadImage
                              src={card.homepage_video_image}
                              alt={card.product_name}
                            />
                          </div>
                        </Link>
                      ) : (
                        <LazyLoadImage
                          src={card.homepage_video_image}
                          alt={card.product_name}
                        />
                      )} */}
                      <LazyLoadImage
                        src={card.homepage_video_image}
                        alt={card.product_name}
                      />
                      <button
                        className="watch-video-btn"
                        onClick={(event) => {
                          openVideo(card.video);
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                      >
                        Watch Video
                      </button>
                      <Link
                        className="download"
                        type="button"
                        to={card.download_link}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        Download
                      </Link>
                    </Link>
                    {card.video
                      ? playingVideo === card.video &&
                        (modal ? (
                          <div
                            className="VideoPopupDiv"
                            id="VideoPopupDiv"
                            style={{ display: "block" }}
                          >
                            <div className="InnerVideoDiv">
                              <video
                                autoPlay={true}
                                controls
                                id="ProductVideo"
                                width="100%"
                              >
                                <track kind="captions" />
                                <source
                                  id="ProductVideoSource"
                                  src={playingVideo}
                                ></source>
                                Sorry, your browser doesn't support embedded
                                videos.
                              </video>
                              <button
                                className="CloseVideoPopupButton"
                                onClick={() => setModal(false)}
                              >
                                <FontAwesomeIcon icon={faCircleXmark} />
                              </button>
                            </div>
                          </div>
                        ) : null)
                      : null}
                  </>
                );
              } else {
                return (
                  <Link to={card.product_slug}>
                    <div className="card-image">
                      <LazyLoadImage src={card.homepage_video_image} alt="" />
                    </div>
                  </Link>
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  });
};

DisplayProducts.propTypes = {
  product: PropTypes.object,
};

export default DisplayProducts;
