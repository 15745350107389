import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import '../../static/css/supportPages/technicalSupport.css'
import { productSupportAPI } from '../../api/productSupportAPI';
import { useTranslation } from 'react-i18next';
// import { forumData } from '../../api/supportForumAPI';
import { AddLangToPath } from '../../helper/AddLanguagePath';

const Button = ({ name, selectedButton, handleButtonClick }) => (
  <button
    style={selectedButton === name ? { backgroundColor: 'rgb(0, 137, 191)', color: 'white' } : null}
    onClick={() => handleButtonClick(name)}>
    {name}
  </button>
);

const Select = ({ options, handleTechnicalSupportSelectedProduct, isTechnicalSupportProductSelected }) => (
  <select onChange={handleTechnicalSupportSelectedProduct} style={{ color: isTechnicalSupportProductSelected ? 'black' : '#8a7575' }} required>
    <option value="" hidden="">Select a Product</option>
    {options.map(option => <option key={uuid()} value={option}>{option}</option>)}
  </select>
);

const TechnicalSupport = () => {
  const { t } = useTranslation();
  document.title = t('support_pages.technical_support.page_title');
  const [isTechnicalSupportProductSelected, setIsTechnicalSupportProductSelected] = useState(false);
  const [isTechnicalSupportIssueSelected, setIsTechnicalSupportIssueSelected] = useState(false);
  const [technicalSupportSelectedProduct, setTechnicalSupportSelectedProduct] = useState("");
  const [technicalSupportSelectedIssue, setTechnicalSupportSelectedIssue] = useState("");
  console.log(technicalSupportSelectedIssue);
  const [version, setVersion] = useState('');
  // support forum search
  // const [searchTerm, setSearchTerm] = useState('');
  // const [searchSuggestions, setSearchSuggestions] = useState([]);
  // const [searchHistory, setSearchHistory] = useState([]);
  // const [searchResults, setSearchResults] = useState([]);
  const [text, setText] = useState('');
  const [selectedButton, setSelectedButton] = useState(t('support_pages.technical_support.buttons.button1'));
  const [openQuestions, setOpenQuestions] = useState(false);
  const [question, setQuestion] = useState('');
  const [showButtons, setShowButtons] = useState(true);
  const [phone, setPhone] = useState();
  const [openMessage, setOpenMessage] = useState(false);
  const [apiResponse, setApiResponse] = useState()
  const [showResponse, setShowResponse] = useState(false);
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: phone,
    technicalSupportSelectedProduct: technicalSupportSelectedProduct,
    version: version,
    message: text
  });
  const [validationMessages, setValidationMessages] = useState({
    firstName: '',
    lastName: '',
    email: '',
    technicalSupportSelectedProduct: technicalSupportSelectedProduct,
    version: version,
    message: text
  });

  // useEffect(() => {
  //   const loadedHistory = localStorage.getItem('searchHistory');
  //   if (loadedHistory) {
  //     setSearchHistory(JSON.parse(loadedHistory));
  //   }
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (apiResponse !== undefined) {
      setShowResponse(true);
      setTimeout(() => {
        setShowResponse(false);
      }, 3000);
    }
  }, [apiResponse]);

  // forum search
  // useEffect(() => {
  //   if (searchTerm.length > 3) {
  //     forumData(`/forumsearchview/?term=${searchTerm}&forum=/forum/`)
  //       .then(data => setSearchSuggestions(data))
  //       .catch(error => console.error(error));
  //   }
  //   else {
  //     setSearchSuggestions([]);
  //   }
  // }, [searchTerm]);

  const buttons = [t('support_pages.technical_support.buttons.button1'), t('support_pages.technical_support.buttons.button2'), t('support_pages.technical_support.buttons.button3')];
  const products = ['Auto FTP Manager', 'Call For Help', 'Dictation Pro', 'Document Translator', 'FTP Manager Lite', 'FTP Manager Pro', 'IP Camera Monitor', 'IP Camera Viewer', 'IP Phone Camera', 'Magnifier - Vision Enhancer', 'Medical Reminder', 'My Screen Recorder', 'My Screen Recorder Pro', 'Paper Scanner Pro', 'Mobile to PC File Transfer', 'Portable Scandroid', 'Screen Cast', 'Security Monitor Pro', 'Senior Safety Phone', 'Text Speaker Live', 'WebCam Monitor'];
  const QUESTIONS = {
    PURCHASED: t('support_pages.technical_support.questions.purchased'),
    PURCHASE: t('support_pages.technical_support.questions.purchase'),
    REQUIRED: t('support_pages.technical_support.questions.required')
  };

  const issues = [t('support_pages.technical_support.issues.issue1'), t('support_pages.technical_support.issues.issue2'), t('support_pages.technical_support.issues.issue3'), t('support_pages.technical_support.issues.issue4'), t('support_pages.technical_support.issues.issue5')];

  const handleTechnicalSupportSelectedProduct = (event) => {
    setIsTechnicalSupportProductSelected(event.target.value !== "");
    setTechnicalSupportSelectedProduct(event.target.value);
    setFormState({
      ...formState,
      technicalSupportSelectedProduct: event.target.value !== "" ? event.target.value : "",
    });
  }

  const handleTechnicalSupportSelectedIssue = (event) => {
    setIsTechnicalSupportIssueSelected(event.target.value !== "");
    setTechnicalSupportSelectedIssue(event.target.value);
    setFormState({
      ...formState,
      technicalSupportSelectedIssue: event.target.value !== "" ? event.target.value : "",
    });
  }

  const handleVersionChange = (event) => {
    let inputValue = event.target.value.replace(/\D/g, '');
    if (inputValue.length > 1) {
      inputValue = inputValue.slice(0, 1) + '.' + inputValue.slice(1);
    }
    setVersion(inputValue);
    setFormState({
      ...formState,
      version: inputValue,
    });
  };

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const handleQuestions = () => {
    setOpenQuestions(true);
    setQuestion(QUESTIONS.PURCHASED);
    setShowButtons(true);
  };

  const changeQuestion = () => {
    if (question === QUESTIONS.PURCHASED) {
      setQuestion(QUESTIONS.PURCHASE);
    } else if (question === QUESTIONS.PURCHASE) {
      setQuestion(QUESTIONS.REQUIRED);
      setShowButtons(false);
    }
  };

  const answerQuestion = () => {
    if (question === QUESTIONS.PURCHASED) {
      window.open(`${process.env.REACT_APP_SHOPPING_CART_URL}/login/`, '_blank');
    }
    else {
      window.location.href = '/priority-support';
    }
  };

  const handleCount = (event) => {
    setText(event.target.value);
    setFormState({
      ...formState,
      message: event.target.value,
    });
  };

  const handleMessage = () => {
    setOpenMessage(!openMessage);
  };

  const handleSupportFormInputs = (event) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value
    }));
    setValidationMessages(prevState => ({
      ...prevState,
      [name]: ''
    }));
  };

  const handleSupportForm = async (event) => {
    setApiResponse(undefined);
    event.preventDefault();
    const formStateCopy = { ...formState };
    delete formStateCopy.contactNumber;
    const isFormFilled = Object.values(formStateCopy).every(field => field !== '');

    if (isFormFilled) {
      const response = await productSupportAPI(formState);
      setApiResponse(response);
      if (response.status === 200) {
        setFormState(prevState => ({
          ...prevState,
          firstName: '',
          lastName: '',
          email: '',
          message: ''
        }));
        setPhone('');
        setText('');
        setValidationMessages({
          firstName: '',
          lastName: '',
          email: '',
          technicalSupportSelectedProduct: '',
          version: '',
          text: ''
        });
        setOpenMessage(false);
      }
    } else {
      setValidationMessages({
        firstName: formState.firstName === '' ? t('support_pages.technical_support.validation_messages.first_name') : '',
        lastName: formState.lastName === '' ? t('support_pages.technical_support.validation_messages.last_name') : '',
        email: formState.email === '' ? t('support_pages.technical_support.validation_messages.email') : '',
        technicalSupportSelectedProduct: formState.technicalSupportSelectedProduct === '' ? t('support_pages.technical_support.validation_messages.product') : '',
        version: formState.version === '' ? t('support_pages.technical_support.validation_messages.version_number') : '',
        message: formState.message === '' ? t('support_pages.technical_support.validation_messages.message') : ''
      });
    }
  };

  const handlePhoneChange = (phone) => {
    setPhone(phone);
    setFormState({
      ...formState,
      contactNumber: phone,
    });
  };

  // forum search
  // const handleSearch = () => {
  //   if (searchTerm.trim() !== '' && !searchHistory.includes(searchTerm)) {
  //     const updatedHistory = [searchTerm, ...searchHistory.slice(0, 3)];
  //     setSearchHistory(updatedHistory);
  //     localStorage.setItem('searchHistory', JSON.stringify(updatedHistory));
  //     setSearchResults([]);
  //     setSearchTerm('');
  //     window.location.href = `${process.env.REACT_APP_SUPPORT_FORUM_URL}/forum-search/?search=${searchTerm}&forum=%2Fforum%2F`
  //   }
  // };

  // const handleKeyPress = (event) => {
  //   if (event.key === 'Enter') {
  //     handleSearch();
  //     setSearchResults([]);
  //     window.location.href = `${process.env.REACT_APP_SUPPORT_FORUM_URL}/forum-search/?search=${searchTerm}&forum=%2Fforum%2F`
  //     setSearchTerm('');
  //   }
  // };

  // const clearInput = () => {
  //   setSearchTerm('');
  //   setSearchResults([]);
  // }

  // const selectedSearch = (result) => {
  //   setSearchTerm(result);
  //   setSearchResults([]);
  //   window.location.href = `${process.env.REACT_APP_SUPPORT_FORUM_URL}/forum-search/?search=${result}&forum=%2Fforum%2F`
  // };

  // const deleteSearch = (result) => {
  //   setSearchHistory(prevHistory => {
  //     const updatedHistory = prevHistory.filter(item => item !== result);
  //     localStorage.setItem('searchHistory', JSON.stringify(updatedHistory));
  //     return updatedHistory;
  //   });
  //   setSearchResults(prevResults => prevResults.filter(item => item !== result));
  // };

  return (
    <>
      <div className='technical-header'>
        <h1>{t('support_pages.technical_support.title')}</h1>
        <p>{t('support_pages.technical_support.description')}</p>
        {/* <div className="search-box">
          <input
            type="text"
            placeholder={t('support_pages.technical_support.search_placeholder')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={() => setSearchResults(searchHistory)}
            onKeyDown={handleKeyPress}
            onBlur={() => {
              setTimeout(() => {
                setSearchResults([]);
                setSearchSuggestions([]);
              }, 100);
            }}
          />
          <div className="search-elements">
            {searchTerm ? (
              <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 32 32" height="32px" id="Слой_1" version="1.1" viewBox="0 0 32 32" width="32px" xmlSpace="preserve" onClick={clearInput}>
                <path d="M17.459,16.014l8.239-8.194c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.391-1.034-0.391-1.428,0  l-8.232,8.187L7.73,6.284c-0.394-0.395-1.034-0.395-1.428,0c-0.394,0.396-0.394,1.037,0,1.432l8.302,8.303l-8.332,8.286  c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.428,0l8.325-8.279l8.275,8.276c0.394,0.395,1.034,0.395,1.428,0  c0.394-0.396,0.394-1.037,0-1.432L17.459,16.014z" fill="#121313" id="Close" /><g /><g /><g /><g /><g /><g />
              </svg>
            ) : (null)}
            <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" viewBox="0 0 72 72" width="128px" height="128px" onClick={handleSearch}>
              <path d="M 31 11 C 19.973 11 11 19.973 11 31 C 11 42.027 19.973 51 31 51 C 34.974166 51 38.672385 49.821569 41.789062 47.814453 L 54.726562 60.751953 C 56.390563 62.415953 59.088953 62.415953 60.751953 60.751953 C 62.415953 59.087953 62.415953 56.390563 60.751953 54.726562 L 47.814453 41.789062 C 49.821569 38.672385 51 34.974166 51 31 C 51 19.973 42.027 11 31 11 z M 31 19 C 37.616 19 43 24.384 43 31 C 43 37.616 37.616 43 31 43 C 24.384 43 19 37.616 19 31 C 19 24.384 24.384 19 31 19 z" />
            </svg>
          </div>
        </div>
        {(searchResults && searchResults.length > 0) || (searchSuggestions && Object.keys(searchSuggestions).length !== 0) ?
          <div className='previous-results'>
            <div className='search-suggestion'>
              <p>{(searchSuggestions && Object.keys(searchSuggestions).length !== 0) ? 'Search Suggestion' : 'Search History'}</p>
            </div>
            {(searchSuggestions && Object.keys(searchSuggestions).length === 0) && searchResults.map((result) => (
              <div className='results' key={result.id}>
                <button className='search-results' onClick={() => selectedSearch(result)}>
                  <svg xmlns="http://www.w3.org/2000/svg" className='resent' viewBox="0 0 24 24"><title />
                    <path d="M12.25,2A9.81,9.81,0,0,0,4.77,5.46L3.41,4.25a1,1,0,0,0-1.07-.16A1,1,0,0,0,1.75,5V9a1,1,0,0,0,1,1h4.5a1,1,0,0,0,.93-.64,1,1,0,0,0-.27-1.11L6.26,6.78a7.86,7.86,0,0,1,6-2.78A8,8,0,1,1,4.72,14.67a1,1,0,0,0-1.89.66A10,10,0,1,0,12.25,2Z" fill="#464646" />
                    <path d="M16,16a1,1,0,0,1-.6-.2l-4-3A1,1,0,0,1,11,12V8a1,1,0,0,1,2,0v3.5l3.6,2.7a1,1,0,0,1,.2,1.4A1,1,0,0,1,16,16Z" fill="#464646" />
                  </svg>
                  <p>{result}</p>
                </button>
                <svg xmlns="http://www.w3.org/2000/svg" className='close' enableBackground="new 0 0 32 32" height="32px" id="Слой_1" version="1.1" viewBox="0 0 32 32" width="32px" xmlSpace="preserve" onClick={() => deleteSearch(result)} >
                  <path d="M17.459,16.014l8.239-8.194c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.391-1.034-0.391-1.428,0  l-8.232,8.187L7.73,6.284c-0.394-0.395-1.034-0.395-1.428,0c-0.394,0.396-0.394,1.037,0,1.432l8.302,8.303l-8.332,8.286  c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.428,0l8.325-8.279l8.275,8.276c0.394,0.395,1.034,0.395,1.428,0  c0.394-0.396,0.394-1.037,0-1.432L17.459,16.014z" fill="#121313" id="Close" /><g /><g /><g /><g /><g /><g />
                </svg>
              </div>
            ))}
            <div className='suggestions' >
              {Object.keys(searchSuggestions).map((result) => (
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#177386" height="1.25rem" width="100%" version="1.1" id="Capa_1" viewBox="0 0 60 60" xmlSpace="preserve">
                    <g>
                      <path d="M26,9.586C11.664,9.586,0,20.09,0,33c0,4.499,1.418,8.856,4.106,12.627c-0.51,5.578-1.86,9.712-3.813,11.666   c-0.304,0.304-0.38,0.768-0.188,1.153C0.276,58.789,0.625,59,1,59c0.046,0,0.093-0.003,0.14-0.01   c0.349-0.049,8.432-1.213,14.317-4.585c3.33,1.333,6.874,2.009,10.544,2.009c14.336,0,26-10.503,26-23.414S40.337,9.586,26,9.586z" />
                      <path d="M55.894,37.042C58.582,33.27,60,28.912,60,24.414C60,11.503,48.337,1,34,1c-8.246,0-15.968,3.592-20.824,9.42   C17.021,8.614,21.38,7.586,26,7.586c15.439,0,28,11.4,28,25.414c0,5.506-1.945,10.604-5.236,14.77   c4.946,1.887,9.853,2.6,10.096,2.634c0.047,0.006,0.094,0.01,0.14,0.01c0.375,0,0.724-0.211,0.895-0.554   c0.192-0.385,0.116-0.849-0.188-1.153C57.753,46.753,56.403,42.619,55.894,37.042z" />
                    </g>
                  </svg>
                  <p>
                    <a key={result.id} href={`${process.env.REACT_APP_SUPPORT_FORUM_URL}${searchSuggestions[result]}`}>{result}</a>
                  </p>
                </div>
              ))}
            </div>
          </div> : null
        }*/}
      </div> 
      <div className="support-form">
        <p>{t('support_pages.technical_support.let_us_know')}</p>
        <div className="support-type">
          {buttons.map(name =>
            <Button key={name} name={name} selectedButton={selectedButton} handleButtonClick={handleButtonClick} />
          )}
        </div>
        {(selectedButton === t('support_pages.technical_support.buttons.button1') || selectedButton === t('support_pages.technical_support.buttons.button2'))
          ?
          (
            <>
              <div className="form-content">
                <label htmlFor="selectApp">{t('support_pages.technical_support.select_app')}</label>
                <div className='help'>
                  <select
                    name="selectApp"
                    id="selectApp"
                    onChange={handleTechnicalSupportSelectedProduct}
                    value={technicalSupportSelectedProduct}
                    style={{ color: isTechnicalSupportProductSelected ? 'black' : '#8a7575' }}
                    required>
                    <option value="" hidden="">{t('support_pages.technical_support.product_choice')}</option>
                    {products.map(product => <option key={product} value={product}>{product}</option>)}
                  </select>
                  <input
                    type="text"
                    placeholder={t('support_pages.technical_support.version_number')}
                    value={version}
                    onChange={handleVersionChange}
                    maxLength="5"
                  />
                </div>
              </div>
              <div className="form-content">
                <label htmlFor="issues">{t('support_pages.technical_support.tell_more')}</label>
                <select
                  name="issues"
                  id="issues"
                  onChange={handleTechnicalSupportSelectedIssue}
                  style={{ color: isTechnicalSupportIssueSelected ? 'black' : '#8a7575' }}
                  required>
                  <option value="" hidden="">{t('support_pages.technical_support.select_issue')}</option>
                  {issues.map(issue => <option key={issue} value={issue}>{issue}</option>)}
                </select>

              </div>
              <div className="form-content">
                <label htmlFor="solution">{t('support_pages.technical_support.solution')}</label>
                <input type="text" name="" id="solution" disabled value={t('support_pages.technical_support.display_solution')} />
              </div>
            </>
          )
          :
          (null)
        }
        {(selectedButton === t('support_pages.technical_support.buttons.button3')) ?
          (
            <div className="form-content">
              <label htmlFor="issues">{t('support_pages.technical_support.how_can_help')}</label>
              <select
                name="issue"
                id="issue"
                onChange={handleTechnicalSupportSelectedIssue}
                style={{ color: isTechnicalSupportIssueSelected ? 'black' : '#8a7575' }} required>
                <option value="" hidden="">{t('support_pages.technical_support.issue_options.option1')}</option>
                <option value="New feature request">{t('support_pages.technical_support.issue_options.option2')}</option>
                <option value="I want to re-sell your Software">{t('support_pages.technical_support.issue_options.option3')}</option>
                <option value="Other">{t('support_pages.technical_support.issue_options.option4')}</option>
              </select>
            </div>
          )
          :
          (null)
        }
        <div className="form-content">
          <p>{t('support_pages.technical_support.need_help')}</p>
          <div className='connect message'>
            <p>{t('support_pages.technical_support.message_box.title')}</p>
            <button onClick={handleMessage}>{t('support_pages.technical_support.message_box.button')}</button>
          </div>
          <div className='connect'>
            <div className='contact-support'>
              <p><Link to={AddLangToPath('/priority-support/')}>{t('support_pages.technical_support.call_back.link')}</Link> {t('support_pages.technical_support.call_back.title')}</p>
              <button
                onClick={handleQuestions}
                disabled={question === QUESTIONS.PURCHASED || question === QUESTIONS.PURCHASE}
                style={(question === QUESTIONS.PURCHASED || question === QUESTIONS.PURCHASE)
                  ? { backgroundColor: 'rgb(85, 85, 85)' } : { backgroundColor: '#0089bf' }}>
                {t('support_pages.technical_support.call_back.button')}
              </button>
            </div>
            {openQuestions ? (
              <div>
                <p>{question}</p>
                {showButtons && (
                  <div>
                    <button onClick={answerQuestion}>{t('support_pages.technical_support.call_back.choice.yes')}</button>
                    <button onClick={changeQuestion} >{t('support_pages.technical_support.call_back.choice.no')}</button>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {openMessage ?
        <div className="request-box">
          <div className="request">
            <h3>{t('support_pages.technical_support.request_form.title')}</h3>
            {showResponse ? <span style={{ color: 'red', fontSize: '0.8vw' }}>{apiResponse.message}</span> : null}
            <div className="request-form">
              <label htmlFor="firstName">{t('support_pages.technical_support.request_form.f_name')}</label>
              <div></div>
              <div className='input-fields'>
                <input
                  type="text"
                  name='firstName'
                  id='name'
                  placeholder={t('support_pages.technical_support.request_form.f_name_placeholder')}
                  value={formState.firstName}
                  onChange={handleSupportFormInputs} required />
                <p>{validationMessages.firstName}</p>
              </div>
              <div>
                <input
                  type="text"
                  name='lastName'
                  placeholder={t('support_pages.technical_support.request_form.l_name_placeholder')}
                  value={formState.lastName}
                  onChange={handleSupportFormInputs} required />
                <p>{validationMessages.lastName}</p>
              </div>
              <label htmlFor="email">Email</label>
              <label htmlFor="contactNumber">{t('support_pages.technical_support.request_form.phone')}</label>
              <div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder='you@domain.com'
                  value={formState.email}
                  onChange={handleSupportFormInputs} required />
                <p>{validationMessages.email}</p>
              </div>
              <div>
                <PhoneInput
                  name='contactNumber'
                  country={'us'}
                  value={phone}
                  onChange={handlePhoneChange} />
              </div>
              <label htmlFor="technicalSupportSelectedProduct">{t('support_pages.technical_support.request_form.product_detail')}</label>
              <div></div>
              <div>
                <select
                  name="technicalSupportSelectedProduct"
                  id="application"
                  onChange={handleTechnicalSupportSelectedProduct}
                  value={technicalSupportSelectedProduct}
                  style={{ color: isTechnicalSupportProductSelected ? 'black' : '#8a7575' }}
                  required
                >
                  <option value="" hidden="">{t('support_pages.technical_support.product_choice')}</option>
                  {products.map(product => <option key={product} value={product}>{product}</option>)}
                </select>
                <p>{validationMessages.technicalSupportSelectedProduct}</p>
              </div>
              <div>
                <input
                  type="text"
                  name='version'
                  placeholder={t('support_pages.technical_support.request_form.version_placeholder')}
                  value={version}
                  onChange={handleVersionChange}
                  maxLength="5"
                />
                <p>{validationMessages.version}</p>
              </div>
            </div>
            <div className='message-box'>
              <div className="message-box-content">
                <label htmlFor="message">{t('support_pages.technical_support.request_form.message')}</label>
                <p>{text.length}/1000</p>
              </div>
              <div className="text-message">
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="3"
                  maxLength="1000"
                  placeholder={t('support_pages.technical_support.request_form.message_placeholder')}
                  required
                  value={text}
                  onChange={handleCount}
                  onInvalid={(e) => e.target.setCustomValidity(t('support_pages.technical_support.validation_messages.message'))} onInput={(e) => e.target.setCustomValidity("")}
                />
                <p>{validationMessages.message}</p>
              </div>
            </div>
            <div className='request-form-buttons'>
              <button className='close-form' onClick={handleMessage}>{t('support_pages.technical_support.request_form.close')}</button>
              <button className='submit-form' type='submit' onClick={handleSupportForm} >{t('support_pages.technical_support.request_form.submit')}</button>
            </div>
          </div>
        </div> : null}
    </>

  )
}

Button.propTypes = {
  name: PropTypes.string.isRequired,
  selectedButton: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
};

Select.propTypes = {
  options: PropTypes.array.isRequired,
  handleTechnicalSupportSelectedProduct: PropTypes.func.isRequired,
  isTechnicalSupportIssueSelected: PropTypes.bool.isRequired,
};

export default TechnicalSupport 
